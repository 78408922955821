import { ChevronRightIcon } from "@hifdhtracker/icons/16";
import { MenuIcon } from "@hifdhtracker/icons/20";
import { Logo } from "@hifdhtracker/icons/logo";

import { motion } from "framer-motion";

import { PlusGrid, PlusGridItem, PlusGridRow } from "./plus-grid";
import * as Dialog from "@radix-ui/react-dialog";

const links = [
  { href: "/about", label: "About" },
  { href: "/blog", label: "Blog" },
  { href: "/pricing", label: "Pricing" },
  { href: "/pricing#faqs", label: "FAQs" },
  // {
  //   href:
  //     (import.meta.env.PUBLIC_APP_URL as string | undefined) ??
  //     "https://app.hifdhtracker.com",
  //   label: "Login",
  // },
];

function DesktopNav() {
  return (
    <nav className="relative hidden lg:flex">
      {links.map(({ href, label }) => (
        <PlusGridItem key={href} className="relative flex">
          <a
            href={href}
            className="flex items-center px-4 py-3 text-base font-medium text-neutral-950 bg-blend-multiply hover:bg-black/[2.5%]"
            // {...(href.startsWith("http")
            //   ? { target: "_blank", rel: "noreferrer noopener" }
            //   : {})}
          >
            {label}
          </a>
        </PlusGridItem>
      ))}
    </nav>
  );
}

function MobileNavButton() {
  return (
    <Dialog.Trigger
      className="flex size-12 items-center justify-center self-center rounded-lg data-[hover]:bg-black/5 lg:hidden"
      aria-label="Open main menu"
    >
      <MenuIcon className="size-6" />
    </Dialog.Trigger>
  );
}

function MobileNav() {
  return (
    <Dialog.Content className="lg:hidden" aria-describedby={undefined}>
      <Dialog.Title className="hidden">Main menu</Dialog.Title>
      <div className="flex flex-col gap-6 py-4">
        {links.map(({ href, label }, linkIndex) => (
          <motion.div
            initial={{ opacity: 0, rotateX: -90 }}
            animate={{ opacity: 1, rotateX: 0 }}
            transition={{
              duration: 0.15,
              ease: "easeInOut",
              rotateX: { duration: 0.3, delay: linkIndex * 0.1 },
            }}
            key={href}
          >
            <a href={href} className="text-base font-medium text-gray-950">
              {label}
            </a>
          </motion.div>
        ))}
      </div>
      <div className="absolute left-1/2 w-screen -translate-x-1/2">
        <div className="absolute inset-x-0 top-0 border-t border-black/5" />
        <div className="absolute inset-x-0 top-2 border-t border-black/5" />
      </div>
    </Dialog.Content>
  );
}

function Banner() {
  return (
    <a
      href="#waitlist"
      className="group flex items-center gap-1 rounded-full bg-fuchsia-950/35 px-3 py-0.5 text-sm/6 font-medium text-white hover:bg-fuchsia-950/30"
    >
      Now in private alpha! Join the waitlist.
      <ChevronRightIcon className="size-4 transition-transform ease-in-out group-hover:translate-x-1" />
    </a>
  );
}

export function Navbar() {
  return (
    <header className="pt-12 sm:pt-16">
      <Dialog.Root modal={false}>
        <PlusGrid>
          <PlusGridRow className="relative flex justify-between">
            <div className="relative flex gap-6">
              <PlusGridItem className="py-3">
                <a href="/" title="Home" className="group">
                  <Logo className="h-7 w-auto" />
                </a>
              </PlusGridItem>
              <div className="relative hidden items-center py-3 lg:flex">
                <Banner />
              </div>
            </div>
            <DesktopNav />
            <MobileNavButton />
          </PlusGridRow>
        </PlusGrid>
        <MobileNav />
      </Dialog.Root>
    </header>
  );
}
