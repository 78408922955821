export function MenuIcon(props: React.ComponentProps<"svg">) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path
        d="M2.5 5H17.5M2.5 10H9.16667M2.5 15H13.3333"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
}
